import type { ChangeEvent, FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import React, { useCallback, useMemo, useState } from 'react';
import { Flex, Text } from '@lama/design-system';
import type { Entity } from '@lama/common-types';
import type {
  DataProviderCredentials,
  BusinessApiModel,
  CreateBusinessModelApi,
  UpdateBusinessRequest,
} from '@lama/business-service-client';
import type { Relation, RequirementProperty } from '@lama/contracts';
import {
  applicationHasBusinessSelector,
  applicationHasPersonSelector,
  applicationPrimaryBorrowerIdSelector,
  businessName,
  personFullName,
  relatedBusinessesByRelationsSelector,
  relatedPeopleByRelationsSelector,
} from '@lama/selectors';
import type {
  ApplicationApiModel,
  ApplicationUpdateApiModel,
  PersonApiModel,
  PersonCreateApiModel,
  PersonUpdateApiModel,
  UpdateApplicationRelationParams,
} from '@lama/clients';
import pluralize from 'pluralize';
import { noCase } from 'change-case-all';
import { Checkbox, FormControlLabel } from '@mui/material';
import { getSourcedProperty } from '@lama/properties';
import type { DialogMode } from '../BaseDialog';
import { ModifyItemButton } from '../ModifyItemButton';
import { getInitialFormValues } from '../GenericProperties';
import { EmptyRelationsListImage } from './EmptyRelationsListImage';
import { GenericAddOrEditRelationDialog } from './GenericAddOrEditRelationDialog';
import { RelationsListItem } from './RelationsListItem';

interface RelationsListProps {
  relation: Relation;
  allowedEntityTypes: Entity[];
  requirementName?: string;
  requirementProperties: RequirementProperty[];
  getPayload?: (values: any) => any;
  modifyPersonPayload?: (values: Partial<PersonApiModel>) => any;
  modifyBusinessPayload?: (values: Partial<BusinessApiModel>) => any;
  createPerson: ({ person }: { person: PersonCreateApiModel }) => Promise<string | undefined>;
  createBusiness: ({
    business,
    applicationId,
    dataProvidersCredentials,
  }: {
    business: CreateBusinessModelApi;
    applicationId: string;
    dataProvidersCredentials?: DataProviderCredentials[];
  }) => Promise<void>;
  updatePerson: ({ personId, updatePersonPayload }: { personId: string; updatePersonPayload: PersonUpdateApiModel }) => Promise<void>;
  updateBusiness: ({
    businessId,
    updateBusinessPayload,
  }: {
    businessId: string;
    updateBusinessPayload: UpdateBusinessRequest;
  }) => Promise<void>;
  updateApplication: (payload: { updateApplicationPayload: ApplicationUpdateApiModel }) => Promise<void>;
  addApplicationRelation: (args: UpdateApplicationRelationParams) => Promise<any>;
  removeApplicationRelatedEntity: (args: UpdateApplicationRelationParams) => Promise<any>;
  inviteToApplication: ({ personId }: { personId: string }) => Promise<{ skipInviteEmail?: boolean }>;
  application: ApplicationApiModel;
  loading: boolean;
  addExistingEntityEnabled?: boolean;
  showMarkNoEntitiesCheckbox?: boolean;
}

const getRelevantEntity = (
  entityId: string,
  businesses: ApplicationApiModel['relatedBusinesses'],
  people: ApplicationApiModel['relatedPeople'],
): { entity: Record<string, any>; entityType: Entity } | null => {
  const relevantBusiness = businesses.find(({ business }) => business.id === entityId);

  if (relevantBusiness) {
    return { entity: relevantBusiness.business, entityType: 'business' };
  }
  const relevantPerson = people.find(({ person }) => person.id === entityId);
  if (relevantPerson) {
    return { entity: relevantPerson.person, entityType: 'person' };
  }

  return null;
};

const getListItemSubtitle = (multiEntityTypeMode: boolean, entityId: string, entityType: string, primaryBorrower: string | null) => {
  const entityTypeText = multiEntityTypeMode ? entityType : undefined;
  const borrowerType = primaryBorrower ? (primaryBorrower === entityId ? 'Borrower' : 'Co-Borrower') : undefined;
  if (entityTypeText && borrowerType) {
    return `${entityTypeText} • ${borrowerType}`;
  }
  return entityTypeText ?? borrowerType;
};

type DialogValues = ({ inviteToApplication: boolean } & (Partial<BusinessApiModel> | Partial<PersonApiModel>)) | null;

export const BaseRelationsList: FC<RelationsListProps> = ({
  requirementProperties,
  requirementName,
  relation,
  allowedEntityTypes,
  modifyBusinessPayload,
  modifyPersonPayload,
  createPerson,
  createBusiness,
  updatePerson,
  updateBusiness,
  updateApplication,
  addApplicationRelation,
  removeApplicationRelatedEntity,
  inviteToApplication,
  application,
  loading,
  addExistingEntityEnabled,
  showMarkNoEntitiesCheckbox,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogMode, setDialogMode] = useState<DialogMode>('add');
  const [dialogValues, setDialogValues] = useState<Record<string, any> | null>({ inviteToApplication: true });
  const [dialogInitialEntityType, setDialogInitialEntityType] = useState<Entity | null>(allowedEntityTypes[0] ?? null);
  const [markNoEntities, setMarkNoEntities] = useState<boolean | null>(application.markNoEntitiesToRelation?.includes(relation) ?? null);

  const relevantBusinesses = useMemo(() => relatedBusinessesByRelationsSelector(application, [relation]), [application, relation]);

  const relevantPeople = useMemo(() => relatedPeopleByRelationsSelector(application, [relation]), [application, relation]);

  const primaryBorrowerId = useMemo(
    () => (relation === 'borrower' ? applicationPrimaryBorrowerIdSelector(application) : null),
    [application, relation],
  );

  const hasBeenInvitedByPersonId = useMemo(
    () => Object.fromEntries(relevantPeople.map(({ person: { id, email } }) => [id, !!email && application.invitedUsers?.includes(email)])),
    [application.invitedUsers, relevantPeople],
  );

  const handleCreatePerson = useCallback(
    async ({ person }: { person: any }) => {
      let personId = person.id as string;

      if (!applicationHasPersonSelector(application, personId)) {
        personId = uuidv4();
        await createPerson({ person: { ...person, id: personId } });
      }

      await addApplicationRelation({
        relation,
        entityId: personId,
        entityType: 'person',
        applicationId: application.id,
      });
      return personId;
    },
    [addApplicationRelation, application, createPerson, relation],
  );

  const handleCreateBusiness = useCallback(
    async ({ business }: { business: any }) => {
      let businessId = business.id as string;

      if (!applicationHasBusinessSelector(application, businessId)) {
        businessId = uuidv4();
        await createBusiness({ business: { people: [], ...business, id: businessId }, applicationId: application.id });
      }

      await addApplicationRelation({
        relation,
        entityId: businessId,
        entityType: 'business',
        applicationId: application.id,
      });
    },
    [addApplicationRelation, application, createBusiness, relation],
  );

  const addItem = useCallback(() => {
    setDialogValues({ inviteToApplication: true });
    setDialogMode('add');
    setIsDialogOpen(true);
  }, []);

  const editItem = useCallback(
    (id: string) => {
      const relevantEntity = getRelevantEntity(id, relevantBusinesses, relevantPeople);

      if (relevantEntity) {
        const yearsBack = new Date().getUTCFullYear() - application.leadingReferenceYear;

        const propertiesWithDecidedSource =
          requirementProperties?.map((p) => getSourcedProperty(p, relevantEntity.entity, yearsBack)) ?? [];
        const initialValues = getInitialFormValues(propertiesWithDecidedSource, relevantEntity.entity);
        setDialogValues({ ...initialValues, inviteToApplication: false });
        setDialogInitialEntityType(relevantEntity.entityType);
        setDialogMode('edit');
        setIsDialogOpen(true);
      }
    },
    [application.leadingReferenceYear, relevantBusinesses, relevantPeople, requirementProperties],
  );

  const deleteItem = useCallback(
    async (id: string) => {
      const entityType = relevantBusinesses.some(({ business }) => business.id === id) ? 'business' : 'person';

      await removeApplicationRelatedEntity({
        applicationId: application.id,
        entityId: id,
        entityType,
        relation,
      });
    },
    [application.id, relation, relevantBusinesses, removeApplicationRelatedEntity],
  );

  const handleDialogClose = useCallback(() => {
    setIsDialogOpen(false);
    setDialogValues(null);
  }, []);

  const onInvitePerson = useCallback(
    async (id: string) => {
      const person = relevantPeople.find((p) => p.person.id === id)?.person;
      if (person) {
        await inviteToApplication({ personId: person.id });
      }
    },
    [inviteToApplication, relevantPeople],
  );

  const onSubmit = useCallback(
    async (values: DialogValues, entityType: Entity) => {
      if (entityType === 'business') {
        const modifiedBusinessPayload = modifyBusinessPayload?.(values as Partial<BusinessApiModel>) ?? values;
        await (dialogMode === 'edit'
          ? updateBusiness({ businessId: modifiedBusinessPayload.id, updateBusinessPayload: modifiedBusinessPayload })
          : handleCreateBusiness({ business: modifiedBusinessPayload }));
      } else {
        const modifiedPersonPayload = modifyPersonPayload?.(values as Partial<PersonApiModel>) ?? values;
        const result = await (dialogMode === 'edit'
          ? updatePerson({ personId: modifiedPersonPayload.id, updatePersonPayload: modifiedPersonPayload })
          : handleCreatePerson({ person: modifiedPersonPayload }));

        if (dialogMode === 'add' && values?.inviteToApplication) {
          await inviteToApplication({ personId: result as string });
        }
      }
      handleDialogClose();
    },
    [
      handleDialogClose,
      modifyBusinessPayload,
      dialogMode,
      updateBusiness,
      handleCreateBusiness,
      modifyPersonPayload,
      updatePerson,
      handleCreatePerson,
      inviteToApplication,
    ],
  );

  const onCheckboxValueChange = useCallback(
    async (_event: ChangeEvent, checked: boolean) => {
      setMarkNoEntities(checked);
      if (updateApplication && application.markNoEntitiesToRelation?.includes(relation) && !checked) {
        await updateApplication({
          updateApplicationPayload: { markNoEntitiesToRelation: application.markNoEntitiesToRelation?.filter((r) => r !== relation) },
        });
      }
      if (updateApplication && !application.markNoEntitiesToRelation?.includes(relation) && checked) {
        await updateApplication({
          updateApplicationPayload: { markNoEntitiesToRelation: [...(application.markNoEntitiesToRelation ?? []), relation] },
        });
      }
    },
    [setMarkNoEntities, updateApplication, application, relation],
  );

  const multiEntityTypeMode = useMemo(() => allowedEntityTypes.length > 1, [allowedEntityTypes]);

  const showEntityTypeToggle = useMemo(() => multiEntityTypeMode && dialogMode === 'add', [dialogMode, multiEntityTypeMode]);

  const isEmpty = useMemo(() => relevantBusinesses.length === 0 && relevantPeople.length === 0, [relevantBusinesses, relevantPeople]);

  const existingRelatedEntityIds = useMemo(
    () => [...relevantBusinesses.map(({ business }) => business.id), ...relevantPeople.map(({ person }) => person.id)],
    [relevantBusinesses, relevantPeople],
  );
  const relationDisplayName = useMemo(
    () => requirementName?.toLocaleLowerCase() ?? pluralize(noCase(relation)),
    [requirementName, relation],
  );

  if (!dialogInitialEntityType) {
    return null;
  }

  return (
    <Flex flexDirection={'column'} alignItems={'center'} gap={4}>
      {relevantBusinesses.map((entity) => (
        <RelationsListItem
          key={entity.business.id}
          id={entity.business.id}
          title={businessName(entity.business) ?? ''}
          deleteEnabled
          onDelete={deleteItem}
          onEdit={editItem}
          subtitle={getListItemSubtitle(multiEntityTypeMode, entity.business.id, 'Business', primaryBorrowerId)}
          itemType={'business'}
        />
      ))}
      {relevantPeople.map((entity) => (
        <RelationsListItem
          key={entity.person.id}
          id={entity.person.id}
          title={personFullName(entity.person) ?? ''}
          deleteEnabled
          onDelete={deleteItem}
          onEdit={editItem}
          subtitle={getListItemSubtitle(multiEntityTypeMode, entity.person.id, 'Person', primaryBorrowerId)}
          hasBeenInvited={hasBeenInvitedByPersonId[entity.person.id]}
          inviteAllowed
          onInvite={onInvitePerson}
          itemType={'person'}
        />
      ))}
      {isEmpty ? (
        <Flex flexDirection={'column'} alignItems={'center'} gap={4}>
          <EmptyRelationsListImage />
          {showMarkNoEntitiesCheckbox ? (
            <FormControlLabel
              label={`No ${relationDisplayName} to add`}
              control={<Checkbox checked={!!markNoEntities} onChange={onCheckboxValueChange} />}
              sx={{ marginRight: 0 }}
            />
          ) : (
            <Text variant={'body1'}>{`No ${relationDisplayName} were added yet`}</Text>
          )}
        </Flex>
      ) : null}
      <ModifyItemButton text={'Add'} onClick={addItem} variant={'text'} />
      {isDialogOpen ? (
        <GenericAddOrEditRelationDialog
          properties={requirementProperties}
          open={isDialogOpen}
          onClose={handleDialogClose}
          initialEntityType={dialogInitialEntityType}
          onSubmit={onSubmit}
          initialValues={dialogValues ?? undefined}
          isLoading={loading}
          showEntityTypeToggle={showEntityTypeToggle}
          relation={relation}
          dialogMode={dialogMode}
          application={application}
          existingEntityIds={existingRelatedEntityIds}
          addExistingEntityEnabled={addExistingEntityEnabled}
        />
      ) : null}
    </Flex>
  );
};
