import type { HTMLAttributes } from 'react';
import React from 'react';
import styled from 'styled-components';
import { Flex } from '../Flex';

const StyledModalFooter = styled(Flex)`
  padding-top: 24px;
  box-sizing: border-box;
  flex-shrink: 0; /* Ensures footer doesn't shrink */
`;

export type ModalFooterProps = HTMLAttributes<HTMLDivElement>;

export const ModalFooter: React.FC<ModalFooterProps> = ({ children, ...props }) => (
  <StyledModalFooter flexDirection={'row'} justifyContent={'flex-end'} {...props}>
    {children}
  </StyledModalFooter>
);
