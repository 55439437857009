import styled, { css } from 'styled-components';
import { NumericFormat } from 'react-number-format';
import { height } from 'styled-system';
import type { InputProps } from '../Input.component';
import { Flex } from '../../Flex';
import type { Theme } from '../../../theme';

export type InputSizes = 'l' | 'm';

export const inputIconBySize: Record<InputSizes, number> = {
  l: 24,
  m: 20,
};

export const inputPadding: Record<InputSizes, string> = {
  l: '16px 16px 16px 10px',
  m: '6px 16px 6px 10px',
};

const inputFontSize: Record<InputSizes, string> = {
  l: '16px',
  m: '14px',
};

export type StylingProps = Partial<InputProps> & Partial<Theme> & { focused?: boolean };

const focusedStyles = ({ disabled, error, focused }: StylingProps) =>
  !error &&
  !disabled &&
  focused &&
  css`
    border: ${({ theme }) => `1px solid ${theme.input.focused.borderColor}`};
  `;

const disabledStyles = ({ disabled }: StylingProps) =>
  disabled &&
  css`
    background-color: ${({ theme }) => theme.input.disabled.backgroundColor};
    border: ${({ theme }) => `1px solid ${theme.input.disabled.borderColor}`};
    color: ${({ theme }) => theme.input.disabled.textColor};

    ::placeholder {
      color: ${({ theme }) => theme.input.disabled.textColor};
    }

    cursor: not-allowed;
  `;

const errorStyles = ({ error, disabled }: StylingProps) =>
  !disabled &&
  error &&
  css`
    border: ${({ theme }) => `1px solid ${theme.input.error.borderColor}`};
    &:focus {
      box-shadow: 0px 4px 20px 0px rgba(139, 25, 25, 0.08);
    }
  `;

const fullWidthStyles = ({ fullWidth }: StylingProps) =>
  fullWidth &&
  css`
    width: 100%;
  `;

export const StyledInputContainer = styled(Flex)<StylingProps>`
  position: relative;
  border-radius: 8px;

  border: ${({ theme }) => `1px solid ${theme.input.borderColor}`};
  font-size: ${({ $size }) => inputFontSize[$size ?? 'l']};
  padding: ${({ $size }) => inputPadding[$size ?? 'l']};

  ${errorStyles}
  ${disabledStyles}
  
  ${fullWidthStyles}
  ${focusedStyles}
`;

const inputStyles = css<StylingProps>`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  background-color: transparent;
  width: 100%;
  position: relative;

  font-size: ${({ $size }) => inputFontSize[$size ?? 'l']};
  font-family: 'Poppins';
  width: 'auto';
  color: ${({ theme }) => theme.input.textColor};

  ::placeholder {
    font-size: ${({ $size }) => inputFontSize[$size ?? 'l']};
    font-family: 'Poppins';
    color: ${({ theme }) => theme.input.textColor};
  }

  outline: none;

  ${height}
`;

export const StyledInput = styled.input<StylingProps>`
  ${inputStyles}
`;

export const StyledNumberInput = styled(NumericFormat)<StylingProps>`
  ${inputStyles}
`;
