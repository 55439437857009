import React, { useCallback, useContext } from 'react';
import type { FC } from 'react';
import type { Document } from '@lama/document-service-client';
import { ArbitraryDocumentUploadButton, DocumentBox } from '@lama/app-components';
import { Flex, Text, greyPalette } from '@lama/design-system';
import { useDeleteDocumentMutation } from '../../hooks/react-query/useDeleteDocumentMutation';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { useUploadDocumentMutation } from '../../hooks/react-query/useUploadDocumentMutation';

interface DocumentsCategoryListProps {
  miscellaneousDocuments?: Document[];
}

interface MiscellaneousDocumentBoxProps {
  description: string;
  document: Document;
}

const MiscellaneousDocumentBox: FC<MiscellaneousDocumentBoxProps> = ({ description, document }) => {
  const {
    application: { id: applicationId },
  } = useContext(ApplicationContext);
  const { mutateAsync: deleteDocument } = useDeleteDocumentMutation(applicationId);
  const onDeleteDocument = useCallback(async () => {
    await deleteDocument({ documentId: document?.id });
  }, [deleteDocument, document]);

  return <DocumentBox description={description} document={document} onDocumentRemoved={onDeleteDocument} />;
};

export const MiscellaneousDocumentsList: FC<DocumentsCategoryListProps> = ({ miscellaneousDocuments }) => {
  const {
    application: { id: applicationId },
  } = useContext(ApplicationContext);

  const { mutateAsync: uploadDocument, isPending: isUploadingArbitraryDocument } = useUploadDocumentMutation(applicationId);

  const onUploadGeneralDocument = useCallback(
    async (files: FileList) => {
      if (files?.length) {
        await Promise.all(
          [...files].map((file) =>
            uploadDocument({
              file,
              description: '',
              applicationId,
              entityId: applicationId,
              entityType: 'application',
              topic: '',
            }),
          ),
        );
      }
    },
    [applicationId, uploadDocument],
  );

  return (
    <Flex flexDirection={'column'} gap={6}>
      <Text color={greyPalette[500]}>{'Miscellaneous Documents'}</Text>
      <Flex flexDirection={'column'} gap={6}>
        {miscellaneousDocuments?.map((document) => (
          <MiscellaneousDocumentBox key={document.id} description={document.filename} document={document} />
        ))}
        <ArbitraryDocumentUploadButton onFilesSelected={onUploadGeneralDocument} uploading={isUploadingArbitraryDocument} />
      </Flex>
    </Flex>
  );
};
