/* eslint-disable react/jsx-no-literals */
import React, { useEffect, forwardRef, useRef, useState } from 'react';
import { autoUpdate, useFloating, offset, shift, FloatingPortal } from '@floating-ui/react';
import type { HTMLAttributes, PropsWithChildren, MutableRefObject } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { AnimatePresence } from 'framer-motion';
import { Flex } from '../Flex/Flex.component';
import { Text } from '../Text/Text.component';
import { Backdrop, StyledModal, CloseButton } from './Modal.styles';

export type ModalSize = 'l' | 'm' | 's';

export interface ModalProps extends HTMLAttributes<HTMLDivElement> {
  size?: ModalSize;
  onClose?: () => void;
  open: boolean;
  autoFocus?: boolean;
  disableBackdrop?: boolean;
  title?: string;
  alignTitle?: 'center' | 'left';
  subtitle?: string;
  hideCloseButton?: boolean;
  top?: string;
}

export const Modal = forwardRef<HTMLDivElement, PropsWithChildren<ModalProps>>(
  (
    {
      size = 'l',
      onClose,
      open,
      autoFocus = true,
      disableBackdrop = false,
      children,
      title,
      alignTitle = 'left',
      subtitle,
      hideCloseButton = false,
      ...props
    },
    ref,
  ) => {
    const internalRef = useRef<HTMLDivElement>(null);
    const resolvedRef = (ref as MutableRefObject<HTMLDivElement>) || internalRef;
    const [shouldRender, setShouldRender] = useState(open);

    const { refs } = useFloating({
      strategy: 'fixed',
      middleware: [offset(0), shift()],
      whileElementsMounted: autoUpdate,
    });

    // unmount the modal with a delay
    useEffect(() => {
      if (open) {
        refs.setReference(resolvedRef.current);
        setShouldRender(true);
      } else {
        setTimeout(() => {
          setShouldRender(false);
        }, 300);
      }
    }, [open, refs, resolvedRef]);

    useHotkeys(
      'esc',
      () => {
        if (onClose) {
          onClose();
        }
      },
      { enableOnFormTags: true },
      [onClose, open],
    );

    if (!shouldRender) {
      return null;
    }

    return (
      <FloatingPortal>
        <AnimatePresence>
          {!disableBackdrop && open ? (
            <Backdrop
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.8 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.15 }}
              onClick={onClose}
            />
          ) : null}
          {open ? (
            <Flex ref={refs.setReference} {...props}>
              <StyledModal
                role={'dialog'}
                ref={refs.setFloating}
                size={size}
                tabIndex={autoFocus ? -1 : undefined}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
                top={props.top}
              >
                <Flex flexDirection={'column'} alignItems={alignTitle}>
                  <Text variant={'h6'}>{title}</Text>
                  {subtitle ? (
                    <Text variant={'body1'} textAlign={alignTitle}>
                      {subtitle}
                    </Text>
                  ) : null}
                  {!hideCloseButton && (
                    <CloseButton data-testId={'modal-close'} onClick={onClose}>
                      &times;
                    </CloseButton>
                  )}
                </Flex>
                {children}
              </StyledModal>
            </Flex>
          ) : null}
        </AnimatePresence>
      </FloatingPortal>
    );
  },
);

Modal.displayName = 'Modal';
