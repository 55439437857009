/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useContext, useMemo } from 'react';
import { ToggleButton, useMediaQuery, useTheme } from '@mui/material';
import { useRecoilState } from 'recoil';
import { applicationBorrowingBusinessSelector, initiator as initiatorSelector } from '@lama/selectors';
import { Flex, Text } from '@lama/design-system';
import { useAsyncFn } from 'react-use';
import { useFlags } from 'launchdarkly-react-client-sdk';
import type { UpdateBusinessRequest } from '@lama/business-service-client';
import { uniqBy } from 'lodash-es';
import type { ScreenProps } from '../ScreenProps';
import { BasicScreen } from '../shared/BasicScreen';
import type { RelationToBusiness } from '../../state/applicationState';
import { relationToBusinessState } from '../../state/applicationState';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { useUpdateBusiness } from '../../hooks/react-query/useUpdateBusiness';
import { useUpdatePerson } from '../../hooks/react-query/useUpdatePerson';
import { useAddApplicationRelation } from '../../hooks/react-query/useAddApplicationRelation';
import { useRemoveApplicationRelation } from '../../hooks/react-query/useRemoveApplicationRelation';
import { SoleOwnerIcon } from './SoleOwnerIcon';
import { OneOfVariousOwnersIcon } from './OneOfVariousOwnersIcon';
import { OnBehalfOfOwnersIcon } from './OnBehalfOfOwnersIcon';

interface BusinessRelationCard {
  title: string;
  value: RelationToBusiness;
  Icon: React.FC<{ selected: boolean }>;
}

interface BusinessRelationScreenProps extends ScreenProps {
  title?: string;
  subtitle?: string;
}

export const BusinessRelationScreen: React.FC<BusinessRelationScreenProps> = ({
  onNextClick = () => {},
  onBackClick,
  title,
  subtitle,
  ...stepsNavigationProps
}) => {
  const [relationToBusiness, setRelationToBusiness] = useRecoilState(relationToBusinessState);
  const { application } = useContext(ApplicationContext);
  const { id: applicationId, useOfFunds } = application;
  const { enableBuyerRelation } = useFlags();

  const business = useMemo(() => applicationBorrowingBusinessSelector(application), [application]);

  const initiatorPerson = useMemo(() => initiatorSelector(application), [application]);

  const businessRelations: BusinessRelationCard[] = useMemo(
    () =>
      business
        ? [
            ...(business.legalEntityType !== 'Partnership'
              ? [{ title: `Sole ${enableBuyerRelation ? 'buyer' : 'owner'}`, value: 'SoleOwner' as const, Icon: SoleOwnerIcon }]
              : []),
            ...(business.legalEntityType !== 'Sole Proprietorship'
              ? [
                  {
                    title: `One of various ${enableBuyerRelation ? 'buyers' : 'owners'}`,
                    value: 'OneOfVariousOwners' as const,
                    Icon: OneOfVariousOwnersIcon,
                  },
                ]
              : []),
            {
              title: `Applying on behalf of the ${enableBuyerRelation ? 'buyer' : 'owner'}(s)`,
              value: 'OnBehalfOfOwners' as const,
              Icon: OnBehalfOfOwnersIcon,
            },
          ]
        : [],
    [business, enableBuyerRelation],
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { mutateAsync: updateBusiness } = useUpdateBusiness(applicationId);
  const { mutateAsync: updatePerson } = useUpdatePerson(applicationId);
  const { mutateAsync: addApplicationRelation } = useAddApplicationRelation();
  const { mutateAsync: removeApplicationRelation } = useRemoveApplicationRelation();

  const [{ loading: nextInProgress }, nextClick] = useAsyncFn(async () => {
    if (!initiatorPerson || !business) {
      onNextClick({ useOfFunds, businessUnformed: business?.unformed });
      return;
    }

    const updatedInitiator = {
      ...initiatorPerson,
      position: relationToBusiness !== 'OnBehalfOfOwners' ? 'Owner' : 'Unrelated',
      ...(relationToBusiness === 'SoleOwner' ? { ownershipPercentage: 100 } : {}),
      ...(relationToBusiness !== 'OnBehalfOfOwners' ? { guarantor: true } : {}),
    };

    await updatePerson({ personId: updatedInitiator.id, updatePersonPayload: updatedInitiator });

    let updatedBusinessPeople: UpdateBusinessRequest['people'] = [];
    if (relationToBusiness === 'OnBehalfOfOwners') {
      await addApplicationRelation({ applicationId, entityId: updatedInitiator.id, entityType: 'person', relation: 'guest' });
    } else {
      await removeApplicationRelation({ applicationId, entityId: updatedInitiator.id, entityType: 'person', relation: 'guest' });
      updatedBusinessPeople = uniqBy([updatedInitiator, ...business.people], (p) => p.id);
    }

    await updateBusiness({ businessId: business.id, updateBusinessPayload: { people: updatedBusinessPeople } });

    onNextClick({ useOfFunds, businessUnformed: business?.unformed });
  }, [
    addApplicationRelation,
    applicationId,
    business,
    initiatorPerson,
    onNextClick,
    relationToBusiness,
    removeApplicationRelation,
    updateBusiness,
    updatePerson,
    useOfFunds,
  ]);

  const backClick = useCallback(() => {
    onBackClick?.({ useOfFunds, businessUnformed: business?.unformed });
  }, [business, onBackClick, useOfFunds]);

  const onClick = useCallback(
    (_e: any, value: RelationToBusiness) => {
      setRelationToBusiness(value !== relationToBusiness ? value : null);
    },
    [relationToBusiness, setRelationToBusiness],
  );

  const defaultTitle = business?.legalName ? `My relation to ${business.legalName}` : 'My relation to the business is...';

  return (
    <BasicScreen
      {...stepsNavigationProps}
      title={title ?? defaultTitle}
      onNextClick={nextClick}
      nextEnabled={!!relationToBusiness}
      onBackClick={backClick}
      nextLoading={nextInProgress}
    >
      <Flex flexDirection={isMobile ? 'column' : 'row'} gap={6} pb={isMobile ? 0 : 40} justifyContent={'center'}>
        {businessRelations.map(({ title: relationTitle, Icon, value }) => (
          <ToggleButton
            value={value}
            color={'primary'}
            onClick={onClick}
            selected={relationToBusiness === value}
            sx={{
              padding: isMobile ? 2 : 3,
              '&.Mui-selected': {
                borderColor: 'primary.main',
              },
              width: isMobile ? '100%' : '236px',
            }}
            fullWidth
            key={relationTitle}
          >
            <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'space-between'} gap={4}>
              <Icon selected={relationToBusiness === value} />
              <Text variant={'body1'}>{relationTitle}</Text>
            </Flex>
          </ToggleButton>
        ))}
      </Flex>
    </BasicScreen>
  );
};
