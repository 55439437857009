import { PersonOutline, StoreOutlined, ChevronRight } from '@mui/icons-material';
import { Flex, Text, colors } from '@lama/design-system';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import type { Relation } from '@lama/contracts';
import { capitalCase } from 'change-case-all';

interface CommonRelationsListOptionsButtonProps {
  entityType?: 'business' | 'person';
  name: string;
  onClick: () => void;
  isSelected?: boolean;
  icon?: React.ReactNode;
}

interface SearchResultProps extends CommonRelationsListOptionsButtonProps {
  taxId: string;
  extraDetail: string;
}

interface ExistingEntityBoxProps extends CommonRelationsListOptionsButtonProps {
  relation: Relation;
}

const BaseRelationsListOptionsButtonContainer = styled(Flex)<{ selected?: boolean }>`
    &:hover {
        background-color: ${({ selected }) => (selected ? colors.primary.light : colors.grey['200'])};,
    }`;
interface RelationsListOptionsButtonProps extends CommonRelationsListOptionsButtonProps {
  Details?: React.FC;
}

const BaseRelationsListOptionsButton = ({ entityType, name, onClick, isSelected, Details, icon }: RelationsListOptionsButtonProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const Icon = useMemo(() => {
    switch (entityType) {
      case 'person': {
        return () => <PersonOutline sx={{ fill: colors.primary.main, height: 20, width: 20 }} />;
      }
      case 'business': {
        return () => <StoreOutlined sx={{ fill: colors.primary.main, height: 20, width: 20 }} />;
      }
      default: {
        return () => icon;
      }
    }
  }, [entityType, icon]);
  const onMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);
  const onMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);
  return (
    <BaseRelationsListOptionsButtonContainer
      justifyContent={'flex-start'}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      alignItems={'flex-end'}
      border={'1px solid'}
      borderColor={isSelected ? colors.primary.main : colors.grey['200']}
      borderRadius={'8px'}
      px={4}
      py={4}
      onClick={onClick}
      {...(isSelected ? { backgroundColor: colors.primary.light } : {})}
      selected={isSelected}
    >
      <Flex gap={2} width={'100%'}>
        <Icon />
        <Text maxWidth={'250px'} ellipsis variant={'body1'}>
          {name}
        </Text>
        <Flex paddingLeft={6} alignSelf={'center'}>
          {Details ? <Details /> : null}
        </Flex>
      </Flex>
      {isHovered ? <ChevronRight sx={{ fill: colors.grey['600'], height: 20, width: 20 }} /> : null}
    </BaseRelationsListOptionsButtonContainer>
  );
};

export const SearchResult = ({ entityType, name, taxId, extraDetail, onClick, isSelected }: SearchResultProps) => {
  const formattedTaxId = useMemo(() => {
    if (entityType === 'person') {
      return `SSN ${taxId}`;
    }
    return `TIN ${taxId}`;
  }, [entityType, taxId]);
  const detailsComponent = useCallback(
    () => (
      <Flex gap={2}>
        <Text color={colors.grey['600']} variant={'body2'}>
          {formattedTaxId}
        </Text>
        <Text color={colors.grey['600']} variant={'body2'}>
          {'•'}
        </Text>
        <Text color={colors.grey['600']} variant={'body2'}>
          {extraDetail}
        </Text>
      </Flex>
    ),
    [extraDetail, formattedTaxId],
  );
  return (
    <BaseRelationsListOptionsButton
      entityType={entityType}
      name={name}
      onClick={onClick}
      isSelected={isSelected}
      Details={detailsComponent}
    />
  );
};

export const ExistingEntityBox = ({ entityType, relation, ...props }: ExistingEntityBoxProps) => {
  const detailsComponent = useCallback(
    () => (
      <Flex gap={2}>
        <Text color={colors.grey['600']} variant={'body2'}>
          {capitalCase(relation)}
        </Text>
      </Flex>
    ),
    [relation],
  );
  return <BaseRelationsListOptionsButton entityType={entityType} Details={detailsComponent} {...props} />;
};

export const RelationsListOptionButtonWithIcon = BaseRelationsListOptionsButton;
