import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { greyPalette } from '../../theme';
import type { ModalSize, ModalProps } from './Modal.component';

const modalSizes: Record<ModalSize, { maxWidth?: string; maxHeight: string }> = {
  l: { maxWidth: '800px', maxHeight: '720px' },
  m: { maxHeight: '520px', maxWidth: '600px' },
  s: { maxWidth: '400px', maxHeight: '320px' },
};

export const StyledModal = styled(motion.div)<Partial<ModalProps>>`
  position: fixed;
  top: ${({ top }) => top ?? '50%'};
  left: 50%;
  //override floating-ui styles
  transform: translate(-50%, -50%) !important;
  padding: 24px;
  background: white;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
  padding-top: 24px;
  border-radius: 8px;

  z-index: 9999999;
  min-width: fit-content;

  ${({ size }) =>
    size &&
    css`
      ${modalSizes[size]?.maxWidth ? `max-width: ${modalSizes[size].maxWidth};` : ''}
      ${modalSizes[size]?.maxHeight ? `max-height: ${modalSizes[size].maxHeight};` : ''}
    `}
`;

export const Backdrop = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

export const CloseButton = styled.button`
  position: absolute;
  user-select: none;
  top: 18px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  color: ${greyPalette[500]};
  font-size: 2rem;
`;
