import { Warning } from '@mui/icons-material';
import { Icon } from '@mui/material';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import type { DataNode } from '@lama/design-system';
import { Text, Flex, greyPalette } from '@lama/design-system';
import type { DocumentIssue, DocumentWithIssues } from '@lama/document-service-client';
import { formatValue } from '@lama/data-formatters';
import { Tooltip } from '../Tooltip';
import { DocumentActions } from './DocumentActions';
import { PaperClipIcon } from './assets/PaperClipIcon';
import type { OnMetadataUpdateProps } from './types';

export interface TurnedInDocumentProps {
  document: DocumentWithIssues;
  onDelete?: (e: React.MouseEvent) => Promise<void>;
  onOpen: (e: React.MouseEvent) => void;
  onMetadataUpdate?: (metadata: OnMetadataUpdateProps) => Promise<void>;
  onDownloadDocument?: (e: React.MouseEvent) => void;
  moveToTreeData?: DataNode[];
  onMoveToClick?: (moveToNode: DataNode) => Promise<void>;
}

const DocumentIcon: FC<{ issues?: DocumentIssue[] }> = ({ issues }) => (
  <Flex alignItems={'center'} justifyContent={'center'} minWidth={'34px'} minHeight={'34px'} width={'34px'} height={'34px'}>
    {issues?.length ? (
      <Tooltip color={'warning'} title={issues[0]?.description} placement={'top'}>
        <Icon component={Warning} />
      </Tooltip>
    ) : (
      <PaperClipIcon color={greyPalette[700]} />
    )}
  </Flex>
);

const DocumentDescription: FC<{ description: string; filename: string }> = ({ description, filename }) => (
  <Flex gap={2} alignItems={'center'} minWidth={0}>
    {description ? (
      <Text variant={'body1'} ellipsis>
        {description}
      </Text>
    ) : null}
    {filename ? (
      <>
        {description ? (
          <Text variant={'body1'} color={greyPalette[500]}>
            {'•'}
          </Text>
        ) : null}
        <Text variant={'body1'} color={greyPalette[500]} ellipsis>
          {filename}
        </Text>
      </>
    ) : null}
  </Flex>
);

export const TurnedInDocument: FC<TurnedInDocumentProps> = ({
  document,
  onDelete,
  onOpen,
  onMetadataUpdate,
  onDownloadDocument,
  moveToTreeData,
  onMoveToClick,
}) => {
  const { description, filename, issues, uploaderId } = document;

  const createdAt = useMemo(() => formatValue(document.createdAt, 'date'), [document.createdAt]);
  const fullCreatedAt = useMemo(() => formatValue(document.createdAt, 'datetime'), [document.createdAt]);
  return (
    <Flex gap={4} alignItems={'center'} width={'100%'} minWidth={0}>
      <DocumentIcon issues={issues} />
      <Flex flexDirection={'column'} minWidth={0} gap={'2px'}>
        {description || filename ? <DocumentDescription filename={filename} description={description} /> : null}
        <Flex gap={2} alignItems={'center'}>
          <Tooltip title={fullCreatedAt} placement={'bottom'}>
            <Text variant={'body4'} color={'secondary'} ellipsis>
              {createdAt}
            </Text>
          </Tooltip>
          {uploaderId === 'verify-business' ? (
            <>
              <Text variant={'body4'} color={'secondary'} ellipsis>
                {'•'}
              </Text>
              <Text variant={'body4'} color={'secondary'} ellipsis>
                {'Pulled from SoS'}
              </Text>
            </>
          ) : null}
        </Flex>
      </Flex>
      <Flex justifyContent={'flex-end'} flex={1}>
        <DocumentActions
          onDelete={onDelete}
          onOpen={onOpen}
          onDownloadDocument={onDownloadDocument}
          fileName={filename}
          placeholder={description}
          onMetadataUpdate={onMetadataUpdate}
          moveToTreeData={moveToTreeData}
          onMoveToClick={onMoveToClick}
        />
      </Flex>
    </Flex>
  );
};
