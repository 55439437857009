import React, { useMemo } from 'react';
import type { ApplicationApiModel } from '@lama/clients';
import type { BusinessApiModel } from '@lama/business-service-client';
import { personFullName, relatedBusinessesByIdsSelector } from '@lama/selectors';
import { RelatedCompanyCard } from './RelatedCompanyCard';

interface RelatedCompanyListItemProps {
  application: ApplicationApiModel;
  business: BusinessApiModel;
  ownedBusiness: BusinessApiModel;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
}

export const RelatedCompanyListItem: React.FC<RelatedCompanyListItemProps> = ({
  application,
  business,
  ownedBusiness,
  onDelete,
  onEdit,
}) => {
  const ownerName = useMemo(() => {
    const ownerId = ownedBusiness.people?.[0]?.id ?? ownedBusiness.owningBusinesses?.[0]?.id;
    if (!ownerId) {
      return '';
    }

    const owningPerson = business.people.find((principal) => principal.id === ownerId);

    if (owningPerson) {
      return personFullName(owningPerson);
    }

    const owningBusiness = relatedBusinessesByIdsSelector(application, [ownerId])?.[0]?.business;

    return owningBusiness?.legalName ?? '';
  }, [application, business, ownedBusiness]);

  return (
    <RelatedCompanyCard
      id={ownedBusiness.id}
      name={ownedBusiness.legalName ?? ''}
      ownerName={ownerName}
      deleteEnabled
      onDelete={onDelete}
      onEdit={onEdit}
    />
  );
};
