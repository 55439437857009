import type { HTMLAttributes } from 'react';
import React, { useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Flex } from '../Flex';

const StyledModalContent = styled(Flex)<{ scrollable?: boolean; showBottomShadow: boolean }>`
  flex-grow: 1; /* Allows the content to take the remaining space */
  overflow-y: auto; /* Ensures content scrolls when it exceeds max height */
  overflow-x: hidden;
  box-sizing: border-box; /* Ensure padding is respected within the content area */
  position: relative;
  ${({ scrollable }) =>
    scrollable &&
    css`
      overflow-y: auto;
    `}

  &::after {
    content: '';
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    height: 16px;
    pointer-events: none;
    box-shadow: ${({ showBottomShadow }) => (showBottomShadow ? 'inset 0 -8px 8px -8px rgba(0, 0, 0, 0.2)' : 'none')};
    transition: box-shadow 0.3s;
  }
`;

export interface ModalContentProps extends HTMLAttributes<HTMLDivElement> {
  scrollable?: boolean;
}

export const ModalContent: React.FC<ModalContentProps> = ({ scrollable = false, children, ...props }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [showBottomShadow, setShowBottomShadow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (contentRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
        setShowBottomShadow(scrollTop > 0 && scrollTop + clientHeight < scrollHeight);
      }
    };

    const currentContentRef = contentRef.current;

    if (scrollable && currentContentRef) {
      currentContentRef.addEventListener('scroll', handleScroll);
      handleScroll();
    }

    return () => {
      if (currentContentRef) {
        currentContentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollable]);

  return (
    <StyledModalContent
      mt={2}
      ref={contentRef}
      flexDirection={'column'}
      scrollable={scrollable}
      showBottomShadow={showBottomShadow}
      {...props}
    >
      {children}
    </StyledModalContent>
  );
};
