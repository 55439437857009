/* eslint-disable @typescript-eslint/no-base-to-string */
import type { DefaultTheme } from 'styled-components';
import styled, { css, keyframes } from 'styled-components';
import tinyColor from 'tinycolor2';
import type { DotLoaderProps } from './DotLoader.component';

// Define the keyframes based on props
const createDotAnimation = (theme: DefaultTheme, color?: string) => keyframes`
  0% {
    box-shadow: 6px 0 ${color ?? theme.colors.primary.main},
                -6px 0 ${tinyColor(color ?? theme.colors.primary.main)
                  .brighten(15)
                  .toString()};
    background: ${color ?? theme.colors.primary.main};
  }
  33% {
    box-shadow: 6px 0 ${color ?? theme.colors.primary.main},
                -6px 0 ${tinyColor(color ?? theme.colors.primary.main)
                  .brighten(15)
                  .toString()};
    background: ${tinyColor(color ?? theme.colors.primary.main)
      .brighten(15)
      .toString()};
  }
  66% {
    box-shadow: 6px 0 ${tinyColor(color ?? theme.colors.primary.main)
      .brighten(15)
      .toString()},
                -6px 0 ${color ?? theme.colors.primary.main};
    background: ${tinyColor(color ?? theme.colors.primary.main)
      .brighten(15)
      .toString()};
  }
  100% {
    box-shadow: 6px 0 ${tinyColor(color ?? theme.colors.primary.main)
      .brighten(15)
      .toString()},
                -6px 0 ${color ?? theme.colors.primary.main};
    background: ${color ?? theme.colors.primary.main};
  }
`;

// Styled component using the generated keyframes
export const StyledDotLoader = styled.div<DotLoaderProps>`
  width: 4px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: ${({ color, theme }) =>
    css`
      ${createDotAnimation(theme, color)} 1s infinite linear alternate
    `};
`;
