import React, { useMemo } from 'react';
import { useField } from 'formik';
import { stateNameToAbbreviation, countiesNames, conutyNameByStateAbrreviation } from '@lama/contracts';
import { FormikPicker } from './FormikPicker/FormikPicker.js';
import type { FormikInputProps } from './PropertyFormikInput.js';

type FormikCountyPickerProps = FormikInputProps & {
  stateFieldName: string;
};

export const FormikCountyPicker: React.FC<FormikCountyPickerProps> = ({ stateFieldName, ...props }) => {
  const [, { value: state }] = useField(stateFieldName);

  const stateCounties = useMemo(() => {
    if (!state) {
      return countiesNames;
    }

    const stateAbbreviation: string = state.length > 2 ? stateNameToAbbreviation(state as string) : state;

    return conutyNameByStateAbrreviation[stateAbbreviation] ?? [];
  }, [state]);

  return <FormikPicker values={stateCounties} label={'County'} {...props} />;
};
