import { useAsyncFn } from 'react-use';
import type { ApplicationApiModel, ApplicationCreateApiModel } from '@lama/clients';
import { v4 as uuidv4 } from 'uuid';
import type { ApplicationRelatedBusiness, ApplicationRelatedPerson, ProductPublicData } from '@lama/contracts';
import { useQueryParams } from '../../../hooks/useQueryParams.js';
import { useCreateBusinessMutation } from '../../../hooks/react-query/useCreateBusinessMutation';
import { useCreateApplicationMutation } from '../../../hooks/react-query/useCreateApplicationMutation';

const skipToRegistrationQueryParam = 'skipToRegistration';

export const useCreateApplicationAndRelatedEntities = (partnerId: string, product?: ProductPublicData) => {
  const queryParams = useQueryParams([
    'ref',
    'requestedAmount',
    'salesforceUserId',
    skipToRegistrationQueryParam,
    'utm_source',
    'trackingId',
    'referralId',
  ]);

  const { mutateAsync: createApplication } = useCreateApplicationMutation();
  const { mutateAsync: createBusiness } = useCreateBusinessMutation();

  const [{ loading: creatingApplicationAndRelatedEntities }, createApplicationAndRelatedEntities] = useAsyncFn(
    async (createdPersonId: string) => {
      if (!product) {
        throw new Error('Product is not defined');
      }
      const metadata: ApplicationApiModel['metadata'] = {
        salesforceUserId: queryParams.salesforceUserId ?? undefined,
        utmSource: queryParams.utm_source ?? undefined,
        trackingId: queryParams.trackingId ?? undefined,
        referralId: queryParams.referralId ?? undefined,
      };

      const relatedPeople: ApplicationRelatedPerson[] = [
        {
          personId: createdPersonId,
          relation: product.createInitiatorAsBorrower ? 'borrower' : 'guest',
        },
      ];
      let relatedBusinesses: ApplicationRelatedBusiness[] = [];

      const applicationId = uuidv4();

      if (product.createBorrowingBusiness) {
        const businessId = uuidv4();
        await createBusiness({ business: { id: businessId, people: [] }, applicationId });
        relatedBusinesses = [
          {
            businessId,
            relation: 'borrower',
          },
        ];
      }

      const applicationToCreate: ApplicationCreateApiModel = {
        id: applicationId,
        relatedBusinesses,
        relatedPeople,
        initiator: createdPersonId,
        originatingPartner: partnerId,
        originalProductId: product.id,
        ...(queryParams.ref ? { referredBy: queryParams.ref } : {}),
        ...(queryParams.requestedAmount ? { requestedAmount: Number(queryParams.requestedAmount) } : {}),
        metadata,
      };

      await createApplication({ createApplicationPayload: applicationToCreate });

      return applicationToCreate.id;
    },
    [
      product,
      queryParams.salesforceUserId,
      queryParams.utm_source,
      queryParams.trackingId,
      queryParams.referralId,
      queryParams.ref,
      queryParams.requestedAmount,
      partnerId,
      createApplication,
      createBusiness,
    ],
  );

  return { createApplicationAndRelatedEntities, creatingApplicationAndRelatedEntities };
};
