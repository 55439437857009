import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, Flex, Text, greyPalette } from '@lama/design-system';
import { AddBusiness, ChevronRight, PersonAdd } from '@mui/icons-material';
import type { FC, ReactNode } from 'react';
import {
  businessName,
  businessRelationsSelector,
  getApplicationEntityByIdSelector,
  personFullName,
  personRelationsSelector,
  relatedCompaniesSelector,
} from '@lama/selectors';
import type { ApplicationApiModel, PersonApiModel } from '@lama/clients';
import type { BusinessApiModel } from '@lama/business-service-client';
import { capitalCase } from 'change-case-all';
import { uniqBy } from 'lodash-es';
import { Avatar } from '../Avatar';
import { LoadingWrapper } from '../LoadingWrapper';

const EntityCardAvatar: FC<{ icon?: ReactNode; name?: string; entityType: 'business' | 'person' }> = ({ icon, name, entityType }) => {
  const { firstName, lastName } = useMemo(() => {
    if (entityType === 'business') {
      return {
        firstName: name ?? '',
        lastName: '',
      };
    }
    return {
      firstName: name?.split(' ').at(0) ?? '',
      lastName: name?.split(' ').at(-1) ?? '',
    };
  }, [entityType, name]);

  return <Avatar firstName={firstName} lastName={lastName} icon={icon} />;
};

const EntityCard: FC<{
  entityId?: string;
  entityType: 'business' | 'person';
  name?: string;
  relation?: string;
  icon?: ReactNode;
  onEntitySelected: (entityId?: string) => void;
}> = ({ entityId, entityType, name, relation, icon, onEntitySelected }) => {
  const entitySelectedHandler = useCallback(() => {
    onEntitySelected(entityId);
  }, [entityId, onEntitySelected]);

  return (
    <Flex
      justifyContent={'space-between'}
      alignItems={'center'}
      border={'1px solid'}
      borderColor={greyPalette[300]}
      borderRadius={'8px'}
      px={8}
      py={6}
      height={'100px'}
    >
      <Flex alignItems={'center'} gap={4}>
        <EntityCardAvatar icon={icon} name={name} entityType={entityType} />
        <Flex flexDirection={'column'}>
          <Text variant={'h6'}>{name}</Text>
          <Text>{relation}</Text>
        </Flex>
      </Flex>
      <Button onClick={entitySelectedHandler} variant={'tertiary'} color={'neutral'} endIcon={<ChevronRight />}>
        {'Add'}
      </Button>
    </Flex>
  );
};

const EmptyEntitySelection: FC<{ entityType: 'business' | 'person'; onEntitySelected: (entityId?: string) => void }> = ({
  entityType,
  onEntitySelected,
}) => (
  <EntityCard
    entityType={entityType}
    onEntitySelected={onEntitySelected}
    name={`New ${capitalCase(entityType)}`}
    icon={entityType === 'business' ? <AddBusiness fontSize={'small'} /> : <PersonAdd fontSize={'small'} />}
  />
);

interface ExistingEntitySelectionProps {
  onEntitySelected: (entity?: BusinessApiModel | PersonApiModel) => void;
  entityType: 'business' | 'person';
  excludedEntityIds?: string[];
  application: ApplicationApiModel;
  loading: boolean;
}

const getApplicationBusinessesAndAffiliatesWithRelation = (application: ApplicationApiModel) =>
  uniqBy(
    [
      ...application.relatedBusinesses,
      ...application.relatedBusinesses
        .flatMap(({ business }) => relatedCompaniesSelector(application, business))
        .map((a) => ({
          business: a.business,
          relation: 'Affiliate',
        })),
    ],
    (b) => b.business.id,
  );

const getApplicationPeopleWithRelation = (application: ApplicationApiModel) =>
  uniqBy(
    [
      ...application.relatedPeople,
      ...application.relatedBusinesses
        .flatMap(({ business: { people } }) => people)
        .map((entity) => ({
          person: entity,
          relation: personRelationsSelector(entity.id, application),
        })),
    ],
    (p) => p.person.id,
  );

export const ExistingEntitySelection: FC<ExistingEntitySelectionProps> = ({
  entityType,
  onEntitySelected,
  excludedEntityIds,
  application,
  loading,
}) => {
  const entitySelectedCallback = useCallback(
    (entityId?: string) => {
      const relevantEntity = entityId ? getApplicationEntityByIdSelector(application, entityId) : undefined;
      onEntitySelected(relevantEntity);
    },
    [application, onEntitySelected],
  );

  const entities = useMemo<{ id: string; name: string; relation: string }[]>(() => {
    const relevantEntitites =
      entityType === 'business'
        ? getApplicationBusinessesAndAffiliatesWithRelation(application).map((entity) => ({
            id: entity.business.id,
            name: businessName(entity.business as BusinessApiModel) ?? '',
            relation: businessRelationsSelector(entity.business.id, application),
          }))
        : getApplicationPeopleWithRelation(application).map((entity) => ({
            id: entity.person.id,
            name: personFullName(entity.person),
            relation: personRelationsSelector(entity.person.id, application),
          }));

    return relevantEntitites.filter((entity) => !excludedEntityIds?.includes(entity.id));
  }, [application, entityType, excludedEntityIds]);

  useEffect(() => {
    if (!entities.length) {
      onEntitySelected();
    }
  }, [entities, onEntitySelected]);

  return entities.length ? (
    <Flex flexDirection={'column'} width={'100%'}>
      <LoadingWrapper disabled={loading} loading={loading}>
        <Flex flexDirection={'column'} gap={4}>
          <EmptyEntitySelection onEntitySelected={entitySelectedCallback} entityType={entityType} />
          {entities.map(({ id, name, relation }) => (
            <EntityCard
              key={`existing_entity_${id}`}
              entityId={id}
              entityType={entityType}
              name={name}
              relation={relation}
              onEntitySelected={entitySelectedCallback}
            />
          ))}
        </Flex>
      </LoadingWrapper>
    </Flex>
  ) : null;
};
