import type { FC } from 'react';
import type { SocialPlatform } from '@lama/business-service-client';
import React, { useCallback } from 'react';
import { Box, Link, Stack } from '@mui/material';
import { isNil } from 'lodash-es';
import { formatValue } from '@lama/data-formatters';
import { Flex, Text } from '@lama/design-system';
import styled from 'styled-components';
import { HashLinkIcon } from './assets/HashLinkIcon';
import FacebookIcon from './assets/facebook.svg';
import InstagramIcon from './assets/instagram.svg';
import LinkedinIcon from './assets/linkedin.svg';
import TwitterIcon from './assets/twitter.svg';
import PinterestIcon from './assets/pinterest.svg';
import TiktokIcon from './assets/tiktok.svg';
import YelpIcon from './assets/yelp.svg';
import YouTubeIcon from './assets/youtube.svg';
import { InformationArray } from './InformationArray';

const platformToIcon = {
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  linkedin: LinkedinIcon,
  twitter: TwitterIcon,
  youtube: YouTubeIcon,
  pinterest: PinterestIcon,
  tiktok: TiktokIcon,
  yelp: YelpIcon,
};

const isLink = (string: string) => {
  const regex =
    // eslint-disable-next-line unicorn/no-unsafe-regex
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\w$&+,:;=-]+@)?[\d.A-Za-z-]+|(?:www.|[\w$&+,:;=-]+@)[\d.A-Za-z-]+)((?:\/[%+./~\w-_]*)?\??[\w%&+.;=@-]*#?\w*)?)/;
  return regex.test(string);
};

export type InformationTypes =
  | 'address'
  | 'addresses'
  | 'array'
  | 'boolean'
  | 'currency'
  | 'currencyCompact'
  | 'date'
  | 'datetime'
  | 'decimal'
  | 'document'
  | 'email'
  | 'hashLink'
  | 'industry'
  | 'link'
  | 'linkIcons'
  | 'list'
  | 'location'
  | 'monthsAsYearsAndMonths'
  | 'number'
  | 'object'
  | 'percent'
  | 'phoneNumber'
  | 'social'
  | 'string'
  | 'table'
  | 'threeDecimalPercent'
  | 'website';

export interface InformationLine {
  value: any;
  type?: InformationTypes;
  bold?: boolean;
  underline?: boolean;
  color?: string;
  wrap?: boolean;
}

const WrappedText = styled(Text)`
  ${({ breakWord }: { breakWord: boolean }) => (breakWord ? 'word-break: break-word' : '')}
`;

const getFormat = (type?: InformationTypes): string | undefined => {
  if (
    type &&
    [
      'string',
      'currency',
      'currencyCompact',
      'number',
      'decimal',
      'percent',
      'threeDecimalPercent',
      'date',
      'datetime',
      'boolean',
      'address',
      'addresses',
      'monthsAsYearsAndMonths',
    ].includes(type)
  ) {
    return type;
  }

  return undefined;
};

interface HashLinkProps {
  label: string;
  elementId: string;
}

const HashLink: FC<HashLinkProps> = ({ label, elementId }) => {
  const scrollToElement = useCallback(() => {
    document.querySelector(`#${elementId}`)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [elementId]);

  return (
    <Link underline={'hover'}>
      <Flex alignItems={'center'} justifyContent={'start'} gap={2} onClick={scrollToElement}>
        <Text variant={'body2'}>{label}</Text>
        <Box>
          <HashLinkIcon />
        </Box>
      </Flex>
    </Link>
  );
};

export const Information: FC<InformationLine> = ({ value, type, bold, wrap = true, underline, color }) => {
  if (isNil(value) || value === '' || (Array.isArray(value) && value.length === 0)) {
    return (
      <Text variant={'body2'} color={color}>
        {'-'}
      </Text>
    );
  }

  if ((type === 'array' || !type) && Array.isArray(value)) {
    return <InformationArray values={value} wrap={wrap} />;
  }

  if (type === 'social' && Array.isArray(value)) {
    return value.length ? (
      <Stack direction={'row'} borderRadius={'16px'} bgcolor={'#F3F0FF'} display={'flex'} alignItems={'center'} px={1} pt={0.5} gap={0.5}>
        {value
          .map((v) => v.split('_'))
          .map(([platform, url]) => {
            const SocialIcon = platformToIcon[platform as SocialPlatform] ?? (() => null);
            return (
              <Link key={platform} href={url} target={'_blank'}>
                <SocialIcon />
              </Link>
            );
          })}
      </Stack>
    ) : null;
  }

  if (type === 'linkIcons' && Array.isArray(value) && value.every((v) => typeof v === 'object' && v.iconUrl && v.linkUrl)) {
    return value.length ? (
      <Flex borderRadius={'16px'} backgroundColor={'#F3F0FF'} alignItems={'center'} px={2} pt={1} gap={2}>
        {value.map(({ iconUrl, linkUrl }) => {
          const IconComponent = platformToIcon[iconUrl as SocialPlatform] ?? (() => <img src={iconUrl} height={'16px'} width={'16px'} />);
          return (
            <Link key={linkUrl} href={linkUrl} target={'_blank'}>
              <IconComponent />
            </Link>
          );
        })}
      </Flex>
    ) : null;
  }

  if (type === 'link' || (typeof value === 'string' && isLink(value) && !type)) {
    const linkLabel = Array.isArray(value) ? value[0] : value;
    const linkHref = Array.isArray(value) ? value[1] : value;

    return (
      <Link variant={'subtitle1'} href={linkHref} underline={'hover'} target={'_blank'} rel={'noreferrer'} noWrap>
        <Text variant={'body2'}>{linkLabel}</Text>
      </Link>
    );
  }

  if (type === 'hashLink') {
    if (!value?.[0] || !value?.[1]) {
      return;
    }

    const [label, elementId] = value;

    return <HashLink label={label} elementId={elementId} />;
  }

  if (type === 'email' || type === 'phoneNumber') {
    const linkHref = type === 'email' ? `mailto:${value}` : `tel:${value}`;
    return (
      <Link variant={'subtitle1'} href={linkHref} underline={'hover'} target={'_blank'} rel={'noreferrer'}>
        <WrappedText variant={'body2'} breakWord>
          {value}
        </WrappedText>
      </Link>
    );
  }

  if (type === 'location') {
    return (
      <Link variant={'subtitle1'} href={value} underline={'hover'} target={'_blank'} rel={'noreferrer'} noWrap>
        <Text variant={'body2'}>{'View on map'}</Text>
      </Link>
    );
  }

  if (type === 'address') {
    return (
      <Text variant={'body2'} weight={bold ? 700 : undefined} maxLines={3} underline={underline} color={color} ellipsis={!wrap}>
        {formatValue(value, getFormat(type))}
      </Text>
    );
  }

  return (
    <WrappedText variant={'body2'} color={color} underline={underline} weight={bold ? 700 : undefined} ellipsis={!wrap} breakWord>
      {formatValue(value, getFormat(type))}
    </WrappedText>
  );
};
