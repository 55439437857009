import type { FC } from 'react';
import React, { useCallback, useState } from 'react';
import { Button, Flex, Input } from '@lama/design-system';
import { useAsyncFn } from 'react-use';
import { BaseDialog } from '../BaseDialog/BaseDialog.js';
import type { OnMetadataUpdate } from './types.js';

interface RenameDocumentModalProps {
  onSubmit?: OnMetadataUpdate;
  onClose: () => void;
  placeholder: string;
  fileName?: string;
  open: boolean;
}

export const RenameDocumentModal: FC<RenameDocumentModalProps> = ({
  onSubmit,
  onClose,
  open,
  placeholder: initialPlaceholder,
  fileName: initialFilename,
}) => {
  const [fileName, setFileName] = useState(initialFilename);
  const [placeholder, setPlaceholder] = useState(initialPlaceholder);

  const [{ loading }, handleSubmit] = useAsyncFn(async () => {
    await onSubmit?.({ description: placeholder, filename: fileName });
    onClose();
  }, [onSubmit, placeholder, fileName]);

  const onPlaceholderChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPlaceholder(e.target.value);
  }, []);

  const onFileNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFileName(e.target.value);
  }, []);

  return (
    <BaseDialog onClose={onClose} title={'Rename'} open={open} disablePortal>
      <Flex flexDirection={'column'} gap={4} width={'100%'}>
        <Input label={'Label'} value={placeholder} onChange={onPlaceholderChange} />
        {fileName ? <Input label={'File Name'} value={fileName} onChange={onFileNameChange} /> : null}
      </Flex>
      <Flex width={'100%'} flexDirection={'row'} justifyContent={'flex-end'} gap={2}>
        <Button variant={'tertiary'} color={'neutral'} onClick={onClose}>
          {'Cancel'}
        </Button>
        <Button onClick={handleSubmit} loading={loading}>
          {'Save'}
        </Button>
      </Flex>
    </BaseDialog>
  );
};
